
import request from '@/api'

const commonApi = {}
const urls = [
  { api: 'login', path: 'session/login' }, // 登录
  { api: 'newDeptList', path: 'company/dept/list' }, // 获取岗位信息
  { api: 'jobList', path: 'company/job/list' }, // 获取菜单信息
  { api: 'agreeProtocol', path: 'protocol/agree' } // 用户同意协议
]

urls.forEach((url) => {
  commonApi[url.api || url] = (data, method) => {
    const config = {
      url: `/kbms/${url.path || url}`,
      method: method || 'post',
      data
    }
    return request(config)
  }
})
// 公共下载文件的接口
commonApi.fileDownload = (url, data, method) => {
  const config = {
    url,
    method: url.method || method || 'post',
    data,
    params: (url.method || method) === 'get' ? data : {},
    responseType: 'blob'
  }
  return request(config)
}
export default commonApi